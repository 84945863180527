.sitemap {
    padding-top: 42px;
    margin-bottom: 90px;
    &__title {
        margin-bottom: 32px;
    }
    &__nav {
        margin-bottom: 16px;
        &-link {
            font-weight: 800;
            color: rgb(var(--fireEngineRed));
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
    }
    &__column {
        width: 33.33%;
        margin-bottom: 30px;
    }
    &__category {
        &:not(:last-child) {
            margin-bottom: 22px;
        }
        &-title,
        &-link {
            color: rgb(var(--fireEngineRed));
            &:hover,
            &:focus {
                text-decoration: underline;
            }
        }
        &-title {
            margin-bottom: 16px;
        }
        &-list {
            list-style-position: inside;
        }
        &-item {
            &:not(:last-child) {
                margin-bottom: 12px;
            }
        }
        &-link {
            display: inline-block;
        }
    }
    &__download {
        margin-top: auto;
        &-icon {
            flex-shrink: 0;
            margin-right: 10px;
        }
        &-link {
            font-weight: 800;
            text-decoration: underline;
            color: rgb(var(--fireEngineRed));
            &:hover,
            &:focus {
                text-decoration: none;
            }
        }
    }
}

@media (max-width: 992px) {
    .sitemap {
        padding-top: 30px;
        margin-bottom: 30px;
        &__column {
            width: 50%;
        }
    }
}

@media (max-width: 575px) {
    .sitemap {
        &__column {
            width: 100%;
        }
    }
}